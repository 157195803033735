<template>
  <a-modal
    :title="title"
    :visible="open"
    :confirm-loading="uploading"
    @cancel="importExcelHandleCancel"
    @ok="importExcelHandleChange"
  >
    <a-spin tip="上传中..." :spinning="uploading">
      <a-card title="剧集导入" style="width: 100%">
        <a-upload-dragger
          name="file"
          accept=".mp4"
          :multiple="true"
          :show-upload-list="false"
          list-type="picture"
          :remove="handleRemove"
          :customRequest="handleChange"
          @change="changeFile"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
        </a-upload-dragger>
        <div style="height: 10px;" v-if="fileList.length > 0"></div>
        <div style="margin-bottom: 5px" v-for="(item,index) in fileList" :key="index" >
          <div style="width: 100%;display: flex;justify-content: space-between">
            <div><a-icon type="loading" v-if="item.percent != 100" style="margin-right: 10px"  /> <a-icon type="link" v-if="item.percent == 100" style="margin-right: 10px"  />{{item.name}}</div>
          </div>
          <a-progress style="height: 2px" v-if="item.percent != 100" :percent="item.percent" :show-info="false" />
        </div>
      </a-card>
      <a-card title="中文字幕"  style="width: 100%;margin-top: 10px">
        <a-upload-dragger
          name="file"
          accept=".vtt"
          :multiple="true"
          :remove="false"
          action="https://web.frameflash.com/admin-api/common/local/file/uploadCos"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
        </a-upload-dragger>
        <div style="display: flex;margin-bottom: 5px" v-for="(item,index) in fileZhList" :key="index">
          <div style="margin-right: 10px;">{{item.name}}</div>
          <a-progress :percent="item.percent" />
        </div>
      </a-card>

      <a-card title="英文字幕" style="width: 100%;margin-top: 10px">
        <a-upload-dragger
          name="file"
          accept=".vtt"
          :multiple="true"
          :show-upload-list="false"
          list-type="picture"
          :remove="handleRemoveEn"
          :customRequest="handleChangeEn"
          @change="changeFile"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
        </a-upload-dragger>
        <div style="display: flex;margin-bottom: 5px" v-for="(item,index) in fileEnList" :key="index">
          <div style="margin-right: 10px;">{{item.name}}</div>
          <a-progress :percent="item.percent" />
        </div>
      </a-card>

      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
    </a-spin>
  </a-modal>
</template>

<script>

import { importData } from '@/api/video/gather'
import UploadFileToVOD from "@/api/vod";
import UploadFileToVODFile from "@/api/UploadFileToVOD";
export default {
  name: 'ImportExcel',
  props: {
  },
  components: {
  },
  data () {

    return {
      title: '导入',
      open: false,
      uploadStatus: '',
      previewImage: '',
      fileList: [],
      fileZhList:[],
      fileEnList:[],
      // 是否禁用上传
      uploading: false,
      previewVisible: false,
      updateSupport: 0,
      id:''
    }

  },

  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {


    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false;
    },

    changeFile(info) {
      console.log("11111111111111111111111")
      console.log(info)
      // this.fileList.push({
      //   uid:info.file.uid,
      //   name:info.file.name,
      //   status: 'done',
      //   url:'',
      //   percent:0
      // })
    },
    handleChange(info) {
      this.fileList.push({
        uid:info.file.uid,
        name:info.file.name,
        status: 'done',
        url:'',
        percent:0
      })
      console.log(this.fileList)
      let that = this
      // that.form.videoUrl = ""
      let findIndex= that.fileList.findIndex(i => i.uid == info.file.uid);
      console.log('+_------' +findIndex)
      new UploadFileToVOD([info.file], {
        success: function (fileName) {
          if(findIndex != -1) {
            that.fileList[findIndex].url = fileName[0];
            that.$set( that.fileList[findIndex],'url',fileName[0]);
          }
        },
        vProgress:function (val) {
          if(findIndex != -1) {
            that.fileList[findIndex].percent = val * 100;
            that.$set(that.fileList[findIndex],'percent',val* 100);
          }
        }
      });
    },

    handleChangeZh(info) {
      this.fileZhList.push({
        uid:info.file.uid,
        name:info.file.name,
        status: 'done',
        url:'',
        percent:0
      })
      console.log(this.fileZhList)
      let that = this
      // that.form.videoUrl = ""
      let findIndex= that.fileZhList.findIndex(i => i.uid == info.file.uid);
      console.log('+_------' +findIndex)
      new UploadFileToVOD([info.file], {
        success: function (fileName) {
          if(findIndex != -1) {
            that.fileZhList[findIndex].url = fileName[0];
            that.$set( that.fileZhList[findIndex],'url',fileName[0]);
          }
        },
        vProgress:function (val) {
          if(findIndex != -1) {
            that.fileZhList[findIndex].percent = val * 100;
            that.$set(that.fileZhList[findIndex],'percent',val* 100);
          }
        }
      });
    },

    handleChangeEn(info) {
      this.fileEnList.push({
        uid:info.file.uid,
        name:info.file.name,
        status: 'done',
        url:'',
        percent:0
      })
      console.log(this.fileEnList)
      let that = this
      // that.form.videoUrl = ""
      let findIndex= that.fileEnList.findIndex(i => i.uid == info.file.uid);
      console.log('+_------' +findIndex)
      new UploadFileToVOD([info.file], {
        success: function (fileName) {
          if(findIndex != -1) {
            that.fileEnList[findIndex].url = fileName[0];
            that.$set(that.fileEnList[findIndex],'url',fileName[0]);
          }
        },
        vProgress:function (val) {
          if(findIndex != -1) {
            that.fileEnList[findIndex].percent = val * 100;
            that.$set(that.fileEnList[findIndex],'percent',val* 100);
          }
        }
      });
    },
    /** 导入excel窗体关闭 */
    importExcelHandleCancel (e) {
      this.open = false
      this.fileList = [];
      this.fileEnList = [];
      this.fileZhList = [];
      // 关闭后刷新列表
      this.$emit('ok')
    },
    /** 下载模板操作 */
    importTemplate () {
      this.download('/video/video-gather/importTemplate', {
        ...this.queryParams
      }, `gather_template_${new Date().getTime()}.xlsx`)
    },
    /** 导入excel窗体开启 */
    importExcelHandleOpen (e) {
      this.id = e;
      this.open = true
    },
    beforeUpload (file) {
      this.fileList = [file]
      return false
    },
    /** 导入excel */
    importExcelHandleChange () {
      this.uploading = true
      const { fileList } = this
      const formData = new FormData()
      formData.append('file', fileList[0])
      formData.append('updateSupport', this.updateSupport)

      let arrList =this.fileList.map(i => ({
        serialNumber:i.name.split(".")[0],
        videoUrl:i.url,
        videoId:this.id,
        subtitleCn:'',
        subtitleEn:''
      }))
     let enList = this.fileEnList.map(i => ({
        serialNumber:i.name.split(".")[0],
        videoUrl:i.url,
      }));
      let zhList = this.fileZhList.map(i => ({
        serialNumber:i.name.split(".")[0],
        videoUrl:i.url,
      }))
      enList.forEach((i,idx) => {
        arrList.forEach((j,idx) => {
          if(i.serialNumber == j.serialNumber) {
            arrList[idx].subtitleEn = i.videoUrl
          }
        })
      })
      zhList.forEach((i,idx) => {
        arrList.forEach((j,idx) => {
          if(i.serialNumber == j.serialNumber) {
            arrList[idx].subtitleEn = i.videoUrl
          }
        })
      })
      console.log(arrList);
      importData(JSON.stringify(arrList)).then(response => {
        if(response.code == 1 || response.code == 20001) {
          this.fileList = []
          this.$message.success(response.message)
          this.open = false
          this.$emit('ok')
        }else {
          this.$message.error(response.message)
          return
        }

      }).finally(() => {
        this.uploading = false
        this.$emit('ok')
      })
    },
    handleCheckedUpdateSupport () {
      this.updateSupport = this.updateSupport === 0 ? 1 : 0
    },
    handleRemove () {
      this.fileList = []
      this.uploading = false
    },
    handleRemoveZh () {

      this.fileZhList = [];
      this.uploading = false
    },
    handleRemoveEn () {
      this.fileEnList = [];
      this.fileList = []
      this.uploading = false
    },
  }
}
</script>
